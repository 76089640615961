import Head from "next/head";
import Script from "next/script";

export type StandardHeaderContentProps = {
    title?: string
}

export default function StandardHeaderContent({ title }: StandardHeaderContentProps) {
    // app.flapjack.co
    // const js = "window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-LER6X4LWVF');";
    // app.beta.flapjack.co
    const js = "console.log('test');window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-D3ZCD31P8C');";

    return (<>
        <Head>
            <title>{ title ?`Flapjack - ${title}` : 'Flapjack' }</title>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
            <link
                href="https://uploads-ssl.webflow.com/62474b790e03151c94eb6a86/624cfcc682aadc4ca8cfd70e_flapjack_favicon.png"
                rel="shortcut icon" type="image/x-icon"/>
            <link
                href="https://uploads-ssl.webflow.com/62474b790e03151c94eb6a86/624cfccd92ec87449b4028ca_flapjack_webclip.png"
                rel="apple-touch-icon"/>
        </Head>
    </>);
}